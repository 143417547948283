import React from 'react'
import dynamic from 'next/dynamic'
import Link from 'next/link';
import { MdArrowForward } from '@react-icons/all-files/md/MdArrowForward';
import { useRouter } from 'next/router'
const CustomImage = dynamic(() => import('./CustomImage'));

export default function CareerMappingCTA() {
    const router = useRouter();

    const gotoCareerMapping = () => {
        router.push('/career-mapping');
    };

    return (
        <section className="bg-yellow">
            <div className="d-flex flex-column flex-lg-row">
                <div className="width-change order-2 order-lg-1">
                    <CustomImage srcSetPaths={[{path: '/admission/career-counselling.png?tr=h-445,f-avif', type: 'image/avif'},{path: '/admission/career-counselling.png?tr=h-445,f-auto', type: 'image/webp'}]}
                        mainSrc="/admission/career-counselling.png?tr=h-445,f-png" alt="Collegify Career Counselling" width="auto" height="445px" imgCDN />
                    
                </div>
                <div className="px-4 pb-3 pb-lg-0 order-1 order-lg-2">
                    <h4 className="pb-3 pt-3 font-weight-bold text-muted text-center text-lg-start ms-1">Confused about career? We can help.</h4>
                    <h1 className="text-center text-lg-start">Collegify Career Mapping</h1>
                    <div className="d-flex flex-column gap flex-md-row justify-content-center justify-content-lg-start align-item-center">
                        <button className="btn btn-primary text-white btn-with-icon" onClick={gotoCareerMapping}>
                            Book a session today!
                            <span className="btn-icon"><MdArrowForward /></span>
                        </button>
                        <div className="d-flex justify-content-md-start justify-content-center align-items-center">
                            <h5 className="my-auto"> Already booked? </h5>
                            <Link href={{ pathname: `/career-mapping`, query: { status: true }}}>
                                <a>
                                    <button type="button" className="btn btn-link text-dark px-2" style={{textDecoration: "underline"}}>Check Status</button>
                                </a>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
